<template>
  <div class="tw-p-6 sm:tw-p-10">
    <div class="tw-block sm:tw-flex tw-mb-6 tw-items-center tw-justify-between">
      <h5 class="tw-text-2xl inter-semibold tw-text-black tw-mb-3 sm:tw-mb-0">
        Kategori
      </h5>
      <div>
        <!-- <Button label="Impor" 
          icon="pi pi-cloud-download" 
          iconPos="left" 
          class="tw-h-9 tw-text-sm tw-mr-3 tw-mb-3 sm:tw-mb-0 tw-bg-transparent tw-text-black tw-hover:tw-bg-neutral-300 tw-border-gray-300" 
        />
        <Button label="Ekspor" 
          icon="pi pi-cloud-upload" 
          iconPos="left" 
          class="tw-h-9 tw-text-sm tw-mr-3 tw-mb-3 sm:tw-mb-0 tw-bg-transparent tw-text-black tw-hover:tw-bg-neutral-300 tw-border-gray-300" 
        /> -->
        <Button
          label="Tambah"
          icon="pi pi-plus"
          class="tw-h-9 tw-text-sm tw-mb-3 sm:tw-mb-0 tw-bg-transparent tw-text-white tw-hover:tw-bg-neutral-300 tw-border-blue-light tw-bg-blue-light"
          @click="navigateToCreate"
        />
      </div>
    </div>

    <form @submit.prevent="doSearch()" class="tw-mb-8 tw-flex">
      <span class="p-input-icon-left">
        <i class="pi pi-search" />
        <InputText
          v-model="keyword"
          placeholder="Cari disini"
          class="tw-h-11 md:tw-w-80 tw-w-auto mr-3"
        />
      </span>
      <Button label="Filter" 
        type="submit"
        icon="pi pi-align-center" 
        iconPos="left" 
        class="tw-h-11 tw-text-sm tw-bg-transparent tw-text-black tw-hover:tw-bg-neutral-300 tw-border-gray-300 tw-block md:tw-hidden" 
      />
    </form>

    <div class="tw-hidden sm:tw-block">
      <DataTable
        :value="data"
        :paginator="true"
        :rows="10"
        dataKey="id"
        :rowHover="true"
        responsiveLayout="scroll"
        paginatorTemplate="FirstPageLink PageLinks LastPageLink"
      >
        <template #empty>
          <div class="tw-flex tw-justify-center tw-items-center tw-py-20 tw-text-2xl tw-font-bold">
            Tidak ada data yang ditemukan
          </div>
        </template>
        <template #loading>
          <div class="tw-flex tw-justify-center tw-items-center tw-py-20">
            Loading records, please wait...
          </div>
        </template>
        <Column field="no" header="No." sortable style="width: 2rem"></Column>
        <Column header="Nama Kategori" field="name" class="capitalize" sortable style="min-width: 12rem"></Column>
        <Column header="Aksi" style="min-width: 8rem">
          <template #body="{ data }">
            <a :href="`${data.id}`" class="tw-mr-4" @click.prevent="detailData(data.id)">
              <i class="pi pi-eye tw-text-lg"></i>
            </a>
            <a :href="`${data.id}`" class="tw-mr-4" @click.prevent="editData(data.id)">
              <i class="pi pi-pencil tw-text-lg"></i>
            </a>
          </template>
        </Column>
      </DataTable>
    </div>

    <div class="tw-block sm:tw-hidden">
      <div class="card tw-p-0 tw-mb-5" v-for="(item, i) in card_data" :key="`item_${i}`">
        <div class="tw-px-5 tw-py-3">
          <div class="tw-flex tw-justify-between">
            <h6 class="tw-capitalize tw-mb-1 tw-pt-1">{{ item.name }}</h6>
            <a href="#" @click.prevent="toogle($event, item.id)">
              <i class="pi pi-ellipsis-v"></i>
            </a>
            <Menu ref="menu" id="overlay_menu" :model="menus" :popup="true" />
          </div>
        </div>
      </div>

      <Paginator :rows="limit" 
        :totalRecords="data.length" 
        @page="changePage"
        template="FirstPageLink PageLinks LastPageLink">
      </Paginator>
    </div>
  </div>
</template>

<script>
export default {
  props: {},
  data() {
    return {
      keyword: '',
      offset: 0,
      limit: 10,
      categories: [],
      data: [],
      card_data: [],
      current_id: null,
      menus: [
        {
          label: 'Lihat Detail',
          icon: 'pi pi-eye',
          command: () => {
            this.detailData(this.current_id)
          }
        },
        {
          label: 'Edit',
          icon: 'pi pi-pencil',
          command: () => {
            this.editData(this.current_id)
          }
        },
      ],
    };
  },
  mounted() {
    this.loadData();
  },
  methods: {
    navigateToCreate() {
      this.$router.push({ name: "create-category" });
    },
    editData(id) {
      this.$router.push({ name: "edit-category", params: { id: id } });
    },
    detailData(id) {
      this.$router.push({ name: "detail-category", params: { id: id } });
    },
    toogle(event, id) {
      this.current_id = id
      this.$refs.menu.toggle(event);
    },
    loadData() {
      this.$http
        .get(`${process.env.VUE_APP_PRITI_MAIN_API}/api/v1/categories`)
        .then(response => {
          this.categories = response.data.data.categories
          this.categories.forEach((item, idx) => {
            item.no = idx + 1
          });
          this.data = this.categories
          this.changePage({page: 0})
        })
    },
    doSearch() {
      let keyword = this.keyword.toLowerCase()

      this.data = this.categories.filter(
        (item) => item.name.toLowerCase().includes(keyword)
      );
      this.card_data = this.data
      this.changePage({page: 0})
    },
    changePage(event) {
      let page = event.page + 1
      let offset = (page - 1) * this.limit
      let dataLength = this.data.length

      let breakPoint = page <= Math.floor(dataLength / this.limit)
                  ? offset + this.limit
                  : offset + (dataLength % this.limit)
      
      this.card_data = []
      for (let i = offset; i < breakPoint; i++) {
        const element = this.data[i];
        this.card_data.push(element)
      }
    },
  },
};
</script>

<style scoped lang="scss">

</style>
